import React, { useState } from "react";
import "../Style/main.css";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

import logo from "../Images/logo.png";
import ta1 from "../Images/ta1.png";
import ta2 from "../Images/ta2.png";
import Banner1 from "../Images/Pro1.png";
import Banner2 from "../Images/Pro2.png";
import Banner3 from "../Images/Pro3.png";
import Banner4 from "../Images/Pro4.png";
import Banner5 from "../Images/Pro5.png";
import Banner6 from "../Images/Pro6.png";
import login from "../Images/login.png";
import register from "../Images/register.png";
import gg1 from "../Images/gg1.png";
import gg2 from "../Images/gg2.png";
import gg3 from "../Images/gg3.png";
import gg4 from "../Images/gg4.png";
import tt1 from "../Images/tt1.png";
import tt2 from "../Images/tt2.png";
import g1 from "../Images/g1.png";
import g2 from "../Images/g2.png";
import g3 from "../Images/g3.png";
import g4 from "../Images/g4.png";
import g5 from "../Images/g5.png";
import g6 from "../Images/g6.png";
import g7 from "../Images/g7.png";
import g8 from "../Images/g8.png";
import g9 from "../Images/g9.png";
import g10 from "../Images/g10.png";
import tg1 from "../Images/tg1.png";
import tg2 from "../Images/tg2.png";
import tr1 from "../Images/tr1.png";
import tr2 from "../Images/tr2.png";
import tbt1 from "../Images/tbt1.png";
import tbt2 from "../Images/tbt2.png";
import mtbt1 from "../Images/mtbt1.png";
import mtbt2 from "../Images/mtbt2.png";

export default function Home() {
  const [linkLogin, setLinkLogin] = useState("https://play.lsmrich168.com/login");
  const [linkRegister, setLinkRegister] = useState(
    "https://play.lsmrich168.com/register"
  );
  return (
    <>
      <div>
        <div className="home">
          <div className="pc">
            <div className="bg-animation">
              <div className="b1"></div>
              <div className="b2"></div>
              <div className="b3"></div>
              <div className="b4"></div>
              <div className="b5"></div>
              <div className="b6"></div>
              <div className="b7"></div>
              <div className="b8"></div>
            </div>           
          </div>
          <div className="mb">
            <div className="bg-animation">
              <div className="b01"></div>
              <div className="b02"></div>
              <div className="b04"></div>
              <div className="b05"></div>
              <div className="b06"></div>
              <div className="b07"></div>
              <div className="b08"></div>
              <div className="b09"></div>
              <div className="b10"></div>
            </div>           
          </div>     
          <div className="container-group">
            <div className="section">
              <div className="pc">
                <div className="logo-head">
                  <a href={linkLogin}>
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="section-group1">
                  <div className="section-group1-left">
                    <div>
                      <div className="img-tab">
                        <img src={ta1} alt="g-left" />
                      </div>
                      <div className="img-tab">
                        <img src={ta2} alt="g-left" />
                      </div>
                    </div>
                    <div className="text-b">
                      <span className="text-">
                        ยินดีต้อนรับสู่เว็บไซต์เดิมพันออนไลน์ ยอดนิยมของเมืองไทย
                        เราคือผู้ให้บริการเว็บพนัน www.LSMRICH168.com
                        เว็บไซต์ยอดฮิต ด้วยบริการที่หลากหลายตอบโจทย์ผู้ใช้งาน
                        รวมทุกพนันไว้ ในเว็บเดียว แทงบอลออนไลน์ คาสิโนสด
                        ส่งตรงจากบ่อนมาตรฐาน เกมส์สล็อตภาพสวย แทงหวย บนมือถือ
                        พร้อมเกมส์แนวใหม่อย่างอี-สปอร์ตรวมเว็บชั้น
                        นำที่เปิดให้บริการทั้ง SA gaming, Sexy gaming และอื่นๆ
                        อีกมากมาย บริการด้วยระบบออนไลน์มาตรฐานสากล
                        เท่าไหร่ก็จ่าย ไม่มีลิมิตต่อวัน มั่นใจหายห่วง
                        ปลอดภัยไร้กังวล การันตีด้วยจำนวนสมาชิกมากที่สุด ณ ขณะนี้
                      </span>
                    </div>
                  </div>
                  <div className="section-group1-right">
                    <div className="img--bg--pc">
                      <Splide
                        className="slide"
                        options={{
                          rewind: false,
                          gap: "10px",
                          type: "loop",
                          autoplay: true,
                          arrows: false,
                        }}
                      >
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner1} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner2} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner3} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner4} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner5} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={Banner6} className="w-100" />
                          </a>
                        </SplideSlide>
                      </Splide>
                    </div>
                  </div>
                </div>
                <div className="section-group2">
                  <div className="login-register">
                    <a href={linkLogin}>
                      <img src={login} alt="login" />
                    </a>
                    <a href={linkRegister}>
                      <img src={register} alt="register" />
                    </a>
                  </div>
                </div>
                <a href={linkLogin}>
                  <div className="section-group3">
                    <img src={gg1} alt="slot" />
                    <img src={gg2} alt="casino" />
                    <img src={gg3} alt="sport" />
                    <img src={gg4} alt="lotto" />
                  </div>
                </a>
                <div className="section-group4">
                  <div className="group-games-box">
                    <div className="bg-blur"></div>
                    <img src={tt1} className="tab-g1" alt="tab-group-game" />
                    <a href={linkLogin}>
                      <div className="group-games">
                        <img src={g1} alt="group-game" />
                        <img src={g2} alt="group-game" />
                        <img src={g3} alt="group-game" />
                        <img src={g4} alt="group-game" />
                        <img src={g5} alt="group-game" />
                        <img src={g6} alt="group-game" />
                        <img src={g7} alt="group-game" />
                        <img src={g8} alt="group-game" />
                        <img src={g9} alt="group-game" />
                        <img src={g10} alt="group-game" />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="section-group5">
                  <div className="group-games-box2">
                    <div className="bg-blur"></div>
                    <img src={tt2} className="tab-g2" alt="tab-group-game" />
                    <div className="section-group">
                      <div className="section-group5-left">
                        <img src={tg1} className="tg1" alt="tab-group-game" />
                      </div>
                      <div className="section-group5-right">
                        <img src={tg2} className="tg2" alt="tab-group-game" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-group6">
                  <div className="group-games-box3">
                    <div className="bg-blur"></div>
                    <div className="section-group">
                      <div className="section-group6-left">
                        <img src={tr1} className="tr1" alt="tab-group-game" />
                      </div>
                      <div className="section-group6-right">
                        <img src={tr2} className="tr2" alt="tab-group-game" />
                        <div className="text-a">
                          <span className="text--">
                            ​ระบบอัตโนมัติ ฝากถอนรวดเร็ว เพียงไม่กี่วินาที
                            มีตัวตนจริง สถานที่ตั้งจริง ในคาสิโนต่างประเทศ
                            เว็บเดียวจบครบทุกการเดิมพัน ไม่ว่าจะเป็น บาคาร่า
                            สล็อต กีฬา เกมส์ เปิดให้บริการมาไม่ต่ำกว่า 5 ปี
                            ฐานข้อมูลตั้งอยู่ในต่างประเทศ ปลอดภัย 100% เล่นได้
                            ถอนเงินได้จริง สูงสุด 1,000,000 ต่อวัน คาสิโนสด
                            ส่งตรงจากคาสิโน ไม่มีการตัดต่อ ไม่มีขั้นต่ำ
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-group7">
                  <div className="login-register">
                    <a href={linkLogin}>
                      <img src={login} alt="login" />
                    </a>
                    <a href={linkRegister}>
                      <img src={register} alt="register" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb">
              <div className="container-group--">
              <div className="logo-head">
                <a href={linkLogin}>
                  <img src={logo} alt="Logo" />
                </a>
              </div>
              <div className="login-register-bt">
              <div className="login-register">
                <a href={linkRegister}>
                  <img src={register} alt="register" />
                </a>
                <a href={linkLogin}>
                  <img src={login} alt="login" />
                </a>
              </div>
              </div>
              <div className="section-group1-left">
                <div>
                  <div className="img-tab">
                    <img src={ta1} alt="g-left" />
                  </div>
                  <div className="img-tab">
                    <img src={ta2} alt="g-left" />
                  </div>
                </div>
                <div className="text-b">
                  <span className="text-">
                    ยินดีต้อนรับสู่เว็บไซต์เดิมพันออนไลน์ ยอดนิยมของเมืองไทย
                    เราคือผู้ให้บริการเว็บพนัน www.LSMRICH168.com เว็บไซต์ยอดฮิต
                    ด้วยบริการที่หลากหลายตอบโจทย์ผู้ใช้งาน รวมทุกพนันไว้
                    ในเว็บเดียว แทงบอลออนไลน์ คาสิโนสด ส่งตรงจากบ่อนมาตรฐาน
                    เกมส์สล็อตภาพสวย แทงหวย บนมือถือ
                    พร้อมเกมส์แนวใหม่อย่างอี-สปอร์ตรวมเว็บชั้น
                    นำที่เปิดให้บริการทั้ง SA gaming, Sexy gaming และอื่นๆ
                    อีกมากมาย บริการด้วยระบบออนไลน์มาตรฐานสากล เท่าไหร่ก็จ่าย
                    ไม่มีลิมิตต่อวัน มั่นใจหายห่วง ปลอดภัยไร้กังวล
                    การันตีด้วยจำนวนสมาชิกมากที่สุด ณ ขณะนี้
                  </span>
                </div>
              </div>
              <div className="section-group1-right">
                <div className="img--bg--pc">
                  <Splide
                    className="slide"
                    options={{
                      rewind: false,
                      gap: "10px",
                      type: "loop",
                      autoplay: true,
                      arrows: false,
                    }}
                  >
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner1} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner2} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner3} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner4} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner5} className="w-100" />
                      </a>
                    </SplideSlide>
                    <SplideSlide>
                      <a href={linkLogin}>
                        <img src={Banner6} className="w-100" />
                      </a>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
              <div className="section-group3">
              <a href={linkLogin}>
                  <img src={gg1} alt="slot" />
                </a>
                <a href={linkLogin}>
                  <img src={gg2} alt="casino" />
                </a>
                <a href={linkLogin}>
                  <img src={gg3} alt="sport" />
                </a>
                <a href={linkLogin}>
                  <img src={gg4} alt="Lotto" />
                </a>
              </div>
              </div>
              <div className="section-group4">
                <div className="group-games-box">
                  <div className="bg-blur---"></div>
                  <img src={tt1} className="tab-g1" alt="tab-group-game" />
                  <div className="group-games">
                    <div className="img--bg--mb2">
                      <Splide
                        className="slide"
                        options={{
                          rewind: false,
                          gap: "10px",
                          padding: "2rem",
                          type: "loop",
                          autoplay: false,
                          arrows: false,
                          perPage: 2,
                        }}
                      >
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g1} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g2} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g3} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g4} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g5} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g6} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g7} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g8} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g9} className="w-100" />
                          </a>
                        </SplideSlide>
                        <SplideSlide>
                          <a href={linkLogin}>
                            <img src={g10} className="w-100" />
                          </a>
                        </SplideSlide>
                      </Splide>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-group--">
              <div className="section-group5-">
                <div className="bg-blur"></div>
                <img src={tt2} className="tab-g2" alt="tab-group-game" />
                <div className="section-group5--">
                  <img src={tg2} className="tg2" alt="tab-group-game" />
                </div>
                <div className="section-group5--">
                  <img src={tg1} className="tg1" alt="tab-group-game" />
                </div>
              </div>
              <div className="section-group6-">
                <div className="bg-blur"></div>
                <div className="section-group6-right">
                  <img src={tr2} className="tr2" alt="tab-group-game" />
                  <div className="text-a">
                    <span className="text--">
                      ​ระบบอัตโนมัติ ฝากถอนรวดเร็ว เพียงไม่กี่วินาที มีตัวตนจริง
                      สถานที่ตั้งจริง ในคาสิโนต่างประเทศ
                      เว็บเดียวจบครบทุกการเดิมพัน ไม่ว่าจะเป็น บาคาร่า สล็อต
                      กีฬา เกมส์ เปิดให้บริการมาไม่ต่ำกว่า 5 ปี
                      ฐานข้อมูลตั้งอยู่ในต่างประเทศ ปลอดภัย 100% เล่นได้
                      ถอนเงินได้จริง สูงสุด 1,000,000 ต่อวัน คาสิโนสด
                      ส่งตรงจากคาสิโน ไม่มีการตัดต่อ ไม่มีขั้นต่ำ
                    </span>
                  </div>
                </div>
                <div className="section-group6-left">
                  <img src={tr1} className="tr1" alt="tab-group-game" />
                </div>
              </div>
              <div className="login-register---">
                <div className="login-register">
                  <a href={linkRegister}>
                    <img src={register} alt="register" />
                  </a>
                  <a href={linkLogin}>
                    <img src={login} alt="login" />
                  </a>
                </div>
              </div>
                </div>
            </div>
          </div>
          <div className="pc">
            <div className="section-group8">
              <div className="box-tbt1">
                <img src={tbt1} className="tbt1" alt="bank" />
              </div>
              <div className="box-tbt2">
                <img src={tbt2} className="tbt2" alt="bank" />
              </div>
            </div>
          </div>
          <div className="mb">
            <div className="section-group8">
              <div className="box-mtbt1">
                <img src={mtbt1} className="tbt1" alt="bank" />
              </div>
              <div className="box-mtbt2">
                <img src={mtbt2} className="tbt2" alt="bank" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
